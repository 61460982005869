// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hs-popover[data-v-264156bb]{position:relative}.hs-popover__loader[data-v-264156bb]{align-items:center;border:solid thin var(--sortable-table-top-divider);display:flex;height:30px;width:150px}.hs-popover__loader>i[data-v-264156bb]{font-size:16px;height:16px;margin-left:5px;width:16px}.hs-popover__trigger[data-v-264156bb]{display:flex;align-items:center;width:150px;height:30px;border:solid thin var(--sortable-table-top-divider)}.hs-popover__trigger.expanded[data-v-264156bb]{background-color:var(--sortable-table-row-bg)}.hs-popover__trigger[data-v-264156bb]:not(.expanded):hover{background-color:var(--accent-btn)}.hs-popover__trigger:not(.expanded):hover .icon[data-v-264156bb]{color:unset}.hs-popover__trigger .health[data-v-264156bb]{width:129px;margin-left:5px}.hs-popover__trigger .icon[data-v-264156bb]{width:21px;color:var(--primary);margin-top:1px;padding:0 5px 0 3px}.hs-popover__content[data-v-264156bb]{z-index:14;width:150px;border:solid thin var(--sortable-table-top-divider);background-color:var(--sortable-table-row-bg);position:absolute;margin-top:-1px;display:none;visibility:hidden}.hs-popover__content.expanded[data-v-264156bb]{display:inline}.hs-popover__content.expanded-checked[data-v-264156bb]{visibility:visible}.hs-popover__content.out-of-view[data-v-264156bb]{bottom:0;margin-bottom:29px}.hs-popover__content>div[data-v-264156bb]{padding:10px}.hs-popover__content .counts[data-v-264156bb]{display:flex;justify-content:space-between}.hs-popover__content .counts-label[data-v-264156bb]{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.hs-popover__content .scale[data-v-264156bb]{margin-top:10px;display:flex;justify-content:space-between;align-items:baseline}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
